<template>
  <el-scrollbar class="elv-dashboard-container">
    <header class="elv-dashboard-container-title">{{ t('menus.dashboard') }}</header>
    <section ref="dashboardContentRef" v-loading.lock="loading" class="elv-dashboard-container-content">
      <DashBoardModule :moduleTypeInfo="DashboardModuleType.treasury" />
      <DashBoardModule :moduleTypeInfo="DashboardModuleType.reports" />
    </section>
  </el-scrollbar>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Sortable from 'sortablejs'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import DashboardApi from '@/api/DashboardApi'
import { DashboardModuleType } from '@/config/dashboard'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { useDashboardStore } from '@/stores/modules/dashboard'
import DashBoardModule from './components/DashBoardModule.vue'

const { t } = useI18n()
const route = useRoute()
const loading = ref(false)
const dashboardContentRef = useTemplateRef('dashboardContentRef')
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const getDefaultReportsChartTime = () => {
  const params = { entityId: entityId.value, period: 'month', dateRange: [] as Array<string> }
  if (entityStore.entityDetail?.progress?.reportDoneTime) {
    const reportDoneTime = dayjs
      .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
      .subtract(1, 'month')
      .format('YYYY-MM-DD')
    params.dateRange = [
      dayjs(reportDoneTime).startOf('month').format('YYYY-MM-DD'),
      dayjs(reportDoneTime).endOf('month').format('YYYY-MM-DD')
    ]
    return params
  }
  params.dateRange = [
    dayjs().subtract(1, 'month').startOf('month').startOf('day').format('YYYY-MM-DD'),
    dayjs().subtract(1, 'month').endOf('month').startOf('day').format('YYYY-MM-DD')
  ]
  return params
}

const initDashboardData = async (data: Object = {}) => {
  try {
    loading.value = true
    let params: any = getDefaultReportsChartTime()
    if (!isEmpty(data)) {
      params = { ...data }
    }
    dashboardStore.saveReportChartsFilterInfo({ entityId: entityId.value, ...params })
    params.period = params.period.toLocaleUpperCase()
    await dashboardStore.initDashboardData(entityId.value, params)
  } catch (error: any) {
    if (error.message !== 'canceled' && error?.code !== -1) {
      ElMessage.error(error.message)
    }
  } finally {
    loading.value = false
  }
}

/**
 * @description: 初始化拖拽排序
 */
const initSortable = () => {
  nextTick(() => {
    const chartModuleList = dashboardContentRef.value?.querySelectorAll('.elv-dashboard-module-nav-chart-list')
    let originalOrder: any
    chartModuleList?.forEach((element: any) => {
      const type = element?.getAttribute('aria-label')
      Sortable.create(element, {
        animation: 300,
        handle: '.elv-dashboard-chart-item-title-draggable__icon',
        ghostClass: 'elv-dashboard-chart-item__sortable-ghost',
        dragClass: 'elv-dashboard-chart-item__sortable-drag',
        onStart: () => {
          if (
            ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
            !currentEntityPermission.value?.dashboard?.update
          ) {
            ElMessage.warning(t('message.noPermission'))
            return
          }
          originalOrder = Array.from(element.children)
        },
        onEnd: async ({ newIndex, oldIndex }) => {
          if (oldIndex !== newIndex) {
            try {
              let newList: DashboardChartItemTypes[] = []
              loading.value = true
              newList =
                type === 'TREASURY_BALANCE'
                  ? await dashboardStore.treasuryData.sortableChartList.slice()
                  : await dashboardStore.reportsData.sortableChartList.slice()
              newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
              const chartIds = newList.map((item) => {
                return item?.chartId
              })
              await DashboardApi.sortChartDataList(entityId.value, { chartIds, sourceType: type })
              ElMessage.success(t('message.sorted'))
              await initDashboardData()
              loading.value = false
            } catch (error: any) {
              loading.value = false
              // eslint-disable-next-line no-param-reassign
              element.innerHTML = '' // 清空表格内容
              for (const row of originalOrder) {
                element.appendChild(row)
              }
              ElMessage.error(error.message)
            }
          }
        }
      })
    })
  })
}

watch(
  () => route,
  async (newValue) => {
    if (newValue.name === 'entity-dashboard') {
      dashboardStore.deleteReportChartsFilterInfo(entityId.value)
      dashboardStore.treasuryData = {
        fixedChartList: [],
        sortableChartList: []
      }
      dashboardStore.reportsData = {
        fixedChartList: [],
        sortableChartList: []
      }
      await initDashboardData()
      initSortable()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-dashboard-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0px 34px 26px 18px;
  box-sizing: border-box;
  position: relative;

  .el-scrollbar__view {
    height: 100%;
    width: 100%;

    .elv-dashboard-container-content {
      width: 100%;
      box-sizing: border-box;
      padding-left: 2px;
      min-height: calc(100% - 53px);
    }

    .elv-dashboard-container-title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 26px;
      line-height: 33px;
      color: #000000;
      margin-left: 2px;
      margin-top: 20px;
    }
  }

  .elv-dashboard-chart-item.elv-dashboard-chart-item__sortable-ghost {
    border: 1px solid #7596eb;
    box-shadow: 0 0 0 2px #c8d6fa;
  }

  .elv-dashboard-chart-tooltip {
    color: #000;
    font-family: 'Barlow';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: fit-content;
    min-height: 43px;
    box-sizing: border-box;
    display: flex !important;
    align-items: flex-start;
    box-shadow:
      0px 1px 0px 0px rgba(0, 0, 0, 0.05),
      0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
    white-space: pre-wrap !important;

    span {
      font-weight: 500;
      margin-right: 24px;
      max-width: 180px;
    }

    p {
      width: fit-content;
      white-space: nowrap;
    }

    .elv-dashboard-chart-tooltip__list {
      display: flex;
      flex-direction: column;
    }

    .elv-dashboard-chart-tooltip__title {
      min-width: 214px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      P {
        color: #000;
        font-family: 'Barlow';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      span {
        color: #000;
        text-align: right;
        font-family: 'Barlow';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 0;
        margin-left: 16px;
      }
    }

    .elv-dashboard-chart-tooltip__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      p {
        display: flex;
        align-items: center;
      }

      .elv-dashboard-chart-tooltip__item-logo {
        display: block;
        margin-right: 4px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }

      .elv-dashboard-chart-tooltip__item-other {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 4px;
        background-color: #dde1e6;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
