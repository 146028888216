<template>
  <div class="elv-dashboard-component-pie-chart-container">
    <div class="elv-dashboard-component-pie-chart-container-panel">
      <div
        ref="pieChartRef"
        class="elv-dashboard-component-bar-pie-chart"
        :style="{ width: props.chartWidth, height: props.chartHeight }"
      ></div>
      <el-collapse v-model="activeCollapse" accordion class="elv-dashboard-component-pie-chart-item-collapse">
        <el-collapse-item v-for="(item, index) in chartItemList" :key="item.name" :name="`${index + 1}`">
          <template #title>
            <p class="elv-dashboard-component-pie-chart-item-platform-item-title">
              <span class="circle" :style="{ 'background-color': currentPieColor(index) }"></span>
              <el-tooltip
                effect="dark"
                placement="top"
                popper-class="elv-account-drawer-balance-tips"
                overlay-classname="elv-account-drawer-balance-tips"
                :show-after="500"
              >
                <span class="elv-dashboard-component-pie-chart-item-platform-item-name">{{
                  transformI18n(labelFormatter(item.name))
                }}</span>
                <template #content>
                  {{ transformI18n(labelFormatter(item.name)) }}
                </template>
              </el-tooltip>
            </p>
            <span class="line"></span>
            <span
              class="elv-dashboard-component-pie-chart-item-platform-item-value"
              :title="
                fieldValueFormat(
                  item.value,
                  {
                    price: true,
                    keepPoint: true,
                    symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  },
                  'NUMBER'
                )
              "
              >{{
                fieldValueFormat(
                  item.value,
                  {
                    dollar: true,
                    symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  },
                  'NUMBER'
                )
              }}</span
            >
          </template>
          <p
            v-for="currencyItem in item.children"
            :key="currencyItem.name"
            class="elv-dashboard-component-pie-chart-item-currency"
          >
            <span class="elv-dashboard-component-pie-chart-item-currency-item-title">
              <span v-if="currencyItem.isOther" class="circle"></span>
              <img v-else :src="currencyItem.logo" alt="" :onerror="useDefaultImage" />
              <span class="elv-dashboard-component-pie-chart-item-currency-item-name">{{ currencyItem.name }}</span>
            </span>
            <span class="line"></span>
            <span
              class="elv-dashboard-component-pie-chart-item-currency-item-value"
              :title="
                fieldValueFormat(
                  currencyItem.value,
                  {
                    price: true,
                    keepPoint: true,
                    symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  },
                  'NUMBER'
                )
              "
              >{{
                fieldValueFormat(
                  currencyItem.value,
                  {
                    dollar: true,
                    symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  },
                  'NUMBER'
                )
              }}</span
            >
          </p>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { find } from 'lodash-es'
import echarts from '@/plugins/echarts'
import { ref, onBeforeUnmount } from 'vue'
import { transformI18n } from '@/i18n/index'
import { fieldValueFormat } from '@/lib/utils'
import { transactionType } from '@/config/index'
import defaultImg from '@/assets/icons/submit-icon.svg'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { DashboardChartColorType } from '@/config/dashboard'

type CharItemType = {
  name: string
  value: number
  totalAmountFC: number
  children: { name: string; value: number; totalAmountFC: number; logo: string; isOther: boolean }[]
}

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const pieChartRef = useTemplateRef('pieChartRef')
const activeCollapse = ref('1')
let chartInstance: any = null
const chartItemList = ref<Array<CharItemType>>([])

const props = defineProps({
  data: {
    type: Object as () => DashboardChartItemTypes | null,
    required: true
  },
  chartWidth: {
    type: String,
    default: '244px'
  },
  chartHeight: {
    type: String,
    default: '244px'
  },
  timeRange: {
    type: String,
    required: true
  }
})

const currentPieColor = computed(() => (index: number) => {
  return DashboardChartColorType[index] ? DashboardChartColorType[index][0] : ''
})

const labelFormatter = (label: string): string => {
  let title = label
  if (find(transactionType, { value: label })) {
    title = find(transactionType, { value: label })?.label || ''
  }
  return title
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const resetChartData = () => {
  if (props.data && Array.isArray(props.data.chartValueList)) {
    const list: CharItemType[] = props.data.chartValueList.map((item) => {
      return {
        name: item.others ? t('common.others') : item.platformType || item.chartOfAccount?.name || '',
        value: item.totalAmountFC,
        totalAmountFC: item.currencyList
          .reduce((acc: Big, cur: any) => {
            const value = cur.totalAmountFC !== null && cur.totalAmountFC !== '0' ? Math.abs(cur.totalAmountFC) : 0
            return acc.plus(Math.abs(value))
          }, Big(0))
          .toNumber(),
        children: Array.isArray(item.currencyList)
          ? item.currencyList.map((currencyItem) => {
              return {
                name: currencyItem?.others ? t('common.others') : currencyItem.currency,
                totalAmountFC: Math.abs(currencyItem.totalAmountFC),
                value: currencyItem.totalAmountFC,
                logo: currencyItem?.underlyingCurrency?.logo || defaultImg,
                isOther: currencyItem?.others || false
              }
            })
          : []
      }
    })
    chartItemList.value = list
  } else {
    chartItemList.value = []
  }
}

const initChart = () => {
  if (!pieChartRef.value) return
  echarts?.dispose(pieChartRef.value)
  chartInstance = echarts.init(pieChartRef.value, {
    width: props.chartWidth,
    height: props.chartHeight
  })
  const isHasChildren = chartItemList.value.some((item) => item.children?.length > 0)
  const option = {
    legend: {
      orient: 'vertical',
      right: 10,
      top: 'center',
      itemGap: 8,
      icon: 'circle',
      textStyle: {
        color: '#1E2024',
        fontWeight: 400,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 11,
        lineHeight: 14,
        overflow: 'truncate'
      },
      selectedMode: true,
      data: props.data
    },
    tooltip: {
      trigger: 'item',
      padding: 12,
      borderWidth: 0,
      position: 'right',
      formatter: (params: any) => {
        return `<span>${transformI18n(labelFormatter(params?.data?.name))}</span><p>${fieldValueFormat(
          params?.data?.totalAmountFC,
          {
            dollar: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )}</p>`
      },
      className: 'elv-dashboard-chart-tooltip'
    },
    series: isHasChildren
      ? [
          {
            name: '内层',
            type: 'pie',
            radius: ['20%', '50%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.map((item, index) => ({
              name: item.name,
              value: item.totalAmountFC,
              totalAmountFC: item.value,
              label: {
                show: true,
                position: 'inside',
                formatter: `{d}%`,
                color: '#1E2024',
                fontWeight: 500,
                fontSize: 12,
                fontFamily: 'Barlow',
                textBorderColor: '#FFFFFF',
                textBorderWidth: 3,
                overflow: 'truncate'
              },
              itemStyle: {
                color: DashboardChartColorType[index] ? DashboardChartColorType[index][0] : '',
                borderColor: '#fff',
                borderWidth: 2
              }
            }))
            // minAngle: 2
          },
          {
            name: '外层',
            type: 'pie',
            radius: ['50%', '90%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.flatMap((item, chartIndex) =>
              item.children.map((child, index) => ({
                name: child.name,
                value: child.totalAmountFC,
                totalAmountFC: child.value,
                label: {
                  show: false
                },
                itemStyle: {
                  color: DashboardChartColorType[chartIndex] ? DashboardChartColorType[chartIndex][index + 1] : '',
                  borderColor: '#fff',
                  borderWidth: 2
                }
              }))
            )
            // minAngle: 2
          }
        ]
      : [
          {
            name: '内层',
            type: 'pie',
            radius: ['50%', '90%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.map((item, index) => ({
              name: item.name,
              value: item.totalAmountFC,
              totalAmountFC: item.value,
              label: {
                show: true,
                position: 'inside',
                formatter: `{d}%`,
                color: '#1E2024',
                fontWeight: 500,
                fontFamily: 'Barlow',
                textBorderColor: '#FFFFFF',
                textBorderWidth: 1,
                overflow: 'truncate'
              },
              itemStyle: {
                color: DashboardChartColorType[index] ? DashboardChartColorType[index][0] : '',
                borderColor: '#fff',
                borderWidth: 2
              }
            }))
            // minAngle: 2
          }
        ]
  }
  if (chartInstance) {
    chartInstance.clear()
  }
  chartInstance.setOption(option)
}

watch(
  () => props.data,
  () => {
    resetChartData()
    nextTick(() => {
      initChart()
    })
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose()
  }
})
</script>

<style lang="scss">
.elv-dashboard-component-pie-chart-container {
  height: 255px;
  display: flex;
  justify-content: center;

  .elv-dashboard-component-pie-chart-container-panel {
    display: flex;
    align-items: center;
    position: relative;
  }

  .elv-dashboard-component-pie-chart-item-collapse {
    min-width: 210px;
    max-width: 240px;
    height: 100%;
    border: none;
    box-shadow: none;
    margin-left: 40px;
    max-height: 255px;
    overflow: auto;

    .el-collapse-item {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 10px;

      button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
        height: 14px;

        .el-collapse-item__arrow {
          display: none;
        }

        .elv-dashboard-component-pie-chart-item-platform-item-title {
          display: flex;
          align-items: center;
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 11px;
          color: #1e2024;
          line-height: 14px;

          .circle {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 4px;
          }

          .elv-dashboard-component-pie-chart-item-platform-item-name {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .line {
          flex: 1;
          height: 0.5px;
          background-color: #dde1e6;
          margin: 0px 8px;
        }

        .elv-dashboard-component-pie-chart-item-platform-item-value {
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 11px;
          color: #1e2024;
          line-height: 14px;
        }
      }

      .el-collapse-item__wrap {
        background-color: transparent;
        border: none;
        width: calc(100% - 16px);

        .el-collapse-item__content {
          padding-bottom: 0px;
        }

        .elv-dashboard-component-pie-chart-item-currency {
          display: flex;
          align-items: center;
          margin-top: 8px;

          .elv-dashboard-component-pie-chart-item-currency-item-title {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;

            img {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 2px;
            }

            .circle {
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 2px;
              background-color: #dde1e6;
            }
          }

          .line {
            flex: 1;
            height: 0.5px;
            background-color: #dde1e6;
            margin: 0px 8px;
          }

          .elv-dashboard-component-pie-chart-item-currency-item-value {
            font-family: 'Barlow';
            font-weight: 400;
            font-size: 11px;
            color: #1e2024;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>
